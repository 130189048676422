/* Global Styles */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

.contain {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  flex: 1;
}

/* Header Styles */
header {
  background: #444;
  padding: 10px 0;
  border-bottom: 1px solid #555;
  margin-bottom: 20px;
}

header .contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header #nameee {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffcc00;
  padding: 10px;
}

header nav {
  display: flex;
  gap: 15px;
}

header nav .a2 {
  color: #fff;
  transition: color 0.3s;
}

header nav .a2:hover {
  color: #ffcc00;
}

p {
  margin: 0px;
}

/* Main Content Styles */
#bod {
  background: #444;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
}

.mainnn {
  display: flex;
  align-items: center; /* Center align items */
  gap: 20px;
}

.mainnn h1 {
  color: #ffcc00;
  font-size: 1.75rem; /* Slightly smaller title text */
  margin-bottom: 20px;
}

.mainnn img#meh {
  max-width: 250px; /* Larger size */
  height: auto;
  border-radius: 10px;
  border: 2px solid #ffcc00;
  margin-right: 10px;
}

.mainnn .text-container {
  flex: 1;
}

.mainnn p#p1 {
  font-size: 1.1rem; /* Slightly larger body text */
  line-height: 1.6;
  color: #ccc;
  text-align: left;
  margin-bottom: 10px;
}

.mainn {
  display: flex;
  flex-direction: column; /* Changed to column to place title above text */
  gap: 10px; /* Adjusted gap for spacing */
}

.mainn h1 {
  color: #ffcc00;
  font-size: 1.75rem; /* Slightly smaller title text */
  margin-top: 5px;
  margin-bottom: 5px;
}

.mainn p#p1 {
  font-size: 1.1rem; /* Slightly larger body text */
  line-height: 1.6;
  color: #ccc;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/*GPT Styles*/
.gpt-container {
  flex: 1 1 250px;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items */
  text-align: center;
}

.gpt-buttons {
  min-width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 10px;
  background: #555;
  transition: transform 0.3s ease;
}

.gpt-buttons:hover {
  transform: scale(1.05);
}

.gpt {
  max-width: 150px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.gpt-text {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffcc00;
}

.gpt-description {
  font-size: 0.9rem;
  color: #ccc;
  margin-top: 5px;
  text-align: center; /* Center align text */
}

/* Contact/Donate Styles */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 15px; /* Adjusted gap for spacing */
  column-gap: 5px; /* Reduced horizontal gap */
}

.pic-container {
  text-align: center;
  margin: 20px; /* Added margin around each pic-container */
}

.pic {
  max-width: 150px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 3px;
}

.pic-text {
  margin: 0; /* Remove the margin */
  padding: 0; /* Remove any padding if present */
  color: #ccc;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-button {
  background-color: #ffcc00; /* Prominent button background color */
  color: #333;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 5px;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-button:hover {
  background-color: #ffcc00; /* Hover background color */
  color: #333; /* Hover text color */
}

.copy-button svg {
  width: 16px;
  height: 16px;
  fill: #fff; /* Changed icon color */
}

.txtBtn {
  margin-top: 10px;
}

.hide-text {
  display: none;
}
/* Default link style */
.demo-link {
  color: #c7b6b6; /* Change this to the color you want */
  text-decoration: underline;
}

/* Hover effect */
.demo-link:hover {
  color: #3553ac; /* Change this to the color you want on hover */
  text-decoration: none; /* Optionally change this if you want to remove underline on hover */
}

.games {
  background-color: #333;
}

/* Email Styles */
#email {
  display: flex;
  flex-direction: row; /* Changed to row to align items horizontally */
  align-items: center;
  justify-content: center; /* Center horizontally */
  gap: 10px;
  margin-top: 20px;
}

#email img#ePic {
  max-width: 32px;
  height: auto;
}

#numb {
  color: #ffcc00;
  font-size: 1.5rem;
}

/* Footer Styles */
footer {
  background: #444;
  padding: 40px 20px;
  margin-top: 20px;
  flex-shrink: 0;
}

footer a {
  color: #fff;
  margin: 0 10px;
  transition: color 0.3s;
}

footer a:hover {
  color: #ffcc00;
}

footer svg {
  width: 32px;
  height: 32px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  header .contain {
    flex-direction: column;
    align-items: center;
  }

  header nav {
    flex-direction: column;
    align-items: center;
  }

  header nav .a2 {
    margin: 0px 0;
  }

  .mainnn {
    flex-direction: column;
    align-items: center;
  }

  .mainnn h1 {
    font-size: 1.5rem;
  }

  .mainnn img#meh {
    margin: 10px 0;
  }

  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  footer {
    text-align: center;
  }

  .postBox {
    margin: 10px 0 !important; /* Add margin between post boxes */
    width: 100% !important; /* Ensure full width on small screens */
  }
}

/* HomePage Styles */
.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-page > * {
  flex-shrink: 0;
}

.home-page > main {
  flex: 1 0 auto;
}

/* PostIt Styles */
#wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#postComment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px; /* Add margin to the top of the form */
}

#txtBox {
  width: 100%;
}

#msg {
  width: 100%;
  height: 150px; /* Increased height */
  padding: 10px;
  font-size: 1.2rem; /* Larger font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#submit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#result {
  width: 100%;
}

.postBox {
  background: #444;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%; /* Ensure full width on larger screens */
}

.heading {
  color: #ffcc00;
}

.comment {
  color: #ccc;
  margin-bottom: 15px;
  font-size: 1.2rem; /* Larger font size */
}

/*.pics { width: 100%; Make image take up the entire post box }*/

/* Add this CSS to your stylesheet */
.pics {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 1000px; /* Add maximum width */
  max-height: 700px; /* Add maximum height */
  margin: 0 auto; /* Center the image */
  border-radius: 10px;
  border: 2px solid #ffcc00;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden; /* Ensure content doesn't overflow */
  margin: 0 auto; /* Center the container */
}

@media (max-width: 600px) {
  .image-container {
    max-width: 100%;
    max-height: 100%;
  }

  .pics {
    max-width: 97%;
    max-height: 97%;
  }
}

.like,
.dislike,
.collapsible {
  background: transparent;
  color: #ffcc00;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.commentBox {
  padding: 10px;
  background: #555;
  border-radius: 5px;
  margin-top: 10px;
}

.txtBox {
  width: 100%;
}

.replyBox {
  background: #666; /* Different background color for comment boxes */
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.reply {
  color: #fff;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-name {
  margin-top: 5px;
  color: #ccc;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  background-color: #ffcc00;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
